<template>
  <div
    v-if="this.$route.matched.length > 2"
    class="animated fadeIn"
  >
    <router-view />
  </div>
  <div
    v-else
    class="animated fadeIn"
  >
    <b-card no-body>
      <b-card-header>
        Пользователи
        <div class="card-header-actions">
          <small class="text-muted">Дата последнего обновления {{ lastRefreshTime }}</small>
          <b-button
            :disabled="loading"
            variant="primary"
            size="sm"
            class="ml-1"
            @click="$refs.table.refresh()"
          >
            <i
              :class="{'fa-spin' : loading}"
              class="fa fa-refresh"
            />&nbsp;Обновить
          </b-button>
          <b-button
            :disabled="!$store.getters.isAdmin"
            variant="primary"
            size="sm"
            to="contractor-users/invite"
            class="ml-2"
          >
            <i class="fa fa-plus" />&nbsp;Пригласить
          </b-button>
        </div>
      </b-card-header>
      <b-card-body :class="{'loading' : loading}">
        <v-server-table
          id="dataTable"
          ref="table"
          :columns="columns"
          :options="options"
          :theme="theme"
          @loaded="loading = false"
        >
          <span
            slot="actions"
            slot-scope="props"
          >
            <a
              :href="'/contractor-users/id'+props.row.id"
              class="icon-pencil"
            >Изменить контакты</a>
            <a
              class="icon-close"
              @click="deleteUser(props.row.id)"
            />
          </span>
          <span
            slot="email"
            slot-scope="props"
          >
            <a
              :href="`mailto:${props.row.email}`"
              class="icon-email"
            >{{ props.row.email }}</a>
          </span>
          <span
            slot="notificationEmail"
            slot-scope="props"
          >
            <a
              :href="`mailto:${props.row.notificationEmail}`"
              class="icon-email"
            >{{ props.row.notificationEmail }}</a>
          </span>
          <span
            slot="active"
            slot-scope="props"
          >
            <span
              v-if="props.row.active"
              class="fa fa-check text-success bold"
            />
            <span
              v-if="!props.row.active"
              class="fa fa-close text-danger bold"
            />
          </span>
          <span
            slot="roles"
            slot-scope="props"
          >
            <span
              v-for="(role, index) in props.row.roles"
              :key="index"
            >
              <span v-if="index + 1 !== props.row.roles.length">
                <span v-if="role === 'ROLE_ADMIN'">Администратор, </span>
                <span v-else-if="role === 'ROLE_MANAGER'">Менеджер, </span>
                <span v-else-if="role === 'ROLE_VIEW'">Просмотр, </span>
                <span v-else>{{ role }}</span>
              </span>
              <span v-else>
                <span v-if="role === 'ROLE_ADMIN'">Администратор</span>
                <span v-else-if="role === 'ROLE_MANAGER'">Менеджер</span>
                <span v-else-if="role === 'ROLE_VIEW'">Просмотр</span>
                <span v-else>{{ role }}</span>
              </span>
            </span>
          </span>
          <span
            slot="actions"
            slot-scope="props"
          >
            <a
              v-if="$store.getters.isAdmin"
              v-b-tooltip.hover.bottom="'Редактировать'"
              :href="'/contractor-users/id'+props.row.id"
              class="fa fa-pencil fa-lg text-primary table-action-button"
            />
            <a
              v-b-tooltip.hover.bottom="'Просмотреть'"
              :href="`contractors/id${$store.state.user.ownerId}/users/id${props.row.id}`"
              class="fa fa-eye fa-lg text-secondary table-action-button"
            />
            <a
              v-if="$store.getters.isAdmin && !props.row.blocked"
              v-b-tooltip.hover.bottom="'Блокировать'"
              class="fa fa-unlock fa-lg text-danger table-action-button"
              @click="confirmBlockUser(props.row.id)"
            />
            <a
              v-if="$store.getters.isAdmin && props.row.blocked"
              v-b-tooltip.hover.bottom="'Разблокировать'"
              class="fa fa-lock fa-lg text-danger table-action-button"
              @click="confirmUnblockUser(props.row.id)"
            />
          </span>
        </v-server-table>
        <contractor-users-roles-info-table />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue';
import {Event, ServerTable} from 'vue-tables-2';
import notifications from '../../components/mixins/notifications';
import {
  contractorsUserBlock,
  contractorsUsersList,
  contractorsUserUnblock,
  customersUserBlock,
  customersUserUnblock,
  userDelete,
} from '../../services/api';
import moment from 'moment';
import ContractorUsersRolesInfoTable from '@/views/ContractorUsers/ContractorUsersRolesInfoTable';

Vue.use(ServerTable);

export default {
  name: 'ContractorList',
  components: {
    ContractorUsersRolesInfoTable,
    ServerTable,
    Event,
  },
  mixins: [notifications],
  data: function() {
    return {
      lastRefreshTime: null,
      filter: {
        name: null,
        verificationStatus: null,
        email: null,
        phone: null,
        driversFrom: null,
        driversTo: null,
        trucksFrom: null,
        trucksTo: null,
        trailersFrom: null,
        trailersTo: null,
      },
      loading: false,
      columns: ['fullname', 'email', 'notificationEmail', 'active', 'roles', 'actions'],
      data: [],
      options: {
        customFilters: [],
        requestFunction: (data) => {
          this.loading = true;
          this.lastRefreshTime = moment().format('DD.MM.YYYY HH:mm:ss');
          return contractorsUsersList(this.$store.state.user.ownerId, data);
        },
        responseAdapter({data}) {
          return {
            data: data.items,
            count: data.totalCount,
          };
        },
        headings: {
          fullname: 'ФИО',
          email: 'Email',
          notificationEmail: 'Email для уведомлений',
          active: 'Активирован',
          roles: 'Роли',
          actions: '',
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: {
          chunk: 10,
          edge: true,
          nav: 'fixed',
        },
        texts: {
          count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      },
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default',
    };
  },
  methods: {
    async deleteUser(userId) {
      this.loading = true;
      const response = await userDelete(userId);
      if (response && response.status === 200) {
        this.showSuccess('Пользователь был удален');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    updateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] !== '' ? this.filter[field] : null;
        Event.$emit('vue-tables.filter::' + field, val);
      }, 800);
    },
    confirmBlockUser(userId) {
      this.$bvModal
        .msgBoxConfirm('Вы уверены что хотите заблокировать пользователя?', {
          okTitle: 'Да',
          cancelTitle: 'Назад',
        })
        .then((confirm) => {
          if (confirm) {
            this.blockUser(userId);
          }
        });
    },
    async blockUser(userId) {
      this.loading = true;
      const response = await contractorsUserBlock(this.$store.state.user.ownerId, userId);
      if (response && response.status === 200) {
        this.showSuccess('Пользователь заблокирован');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    confirmUnblockUser(userId) {
      this.$bvModal
        .msgBoxConfirm('Вы уверены что хотите раззаблокировать пользователя?', {
          okTitle: 'Да',
          cancelTitle: 'Назад',
        })
        .then((confirm) => {
          if (confirm) {
            this.unblockUser(userId);
          }
        });
    },
    async unblockUser(userId) {
      this.loading = true;
      const response = await contractorsUserUnblock(this.$store.state.user.ownerId, userId);
      if (response && response.status === 200) {
        this.showSuccess('Пользователь заблокирован');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
  },
};
</script>

